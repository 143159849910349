<template>
  <div :id="formVirtualKeyAndId" v-if="version === 'v1' && formVirtualKeyAndId">
    <div v-if="errors && Object.keys(errors).length > 0 && !isSuccess && !isLoading" class="form-message-error mb-4">
      <ul>
        <li v-for="(item, index) in Object.keys(errors)" :key="`${index}-error-item`">
          <span v-if="errors[item] && errors[item][0]">
            {{ errors[item][0] }}
          </span>
        </li>
      </ul>
    </div>
    <div
      v-show="!isLoading && !isSuccess"
      class="tw-grid tw-grid-cols-12 tw-gap-x-3 tw-gap-y-3"
      :data-lifesycle-form-id="formUniqueId"
    >
      <lazy-library-title
        v-if="isFormNameVisible"
        class="tw-col-span-12"
        tag="h3"
        :text="form.name"
      ></lazy-library-title>
      <template v-for="(field, fieldIndex) in form.fields" :key="`form-field-${fieldIndex}`">
        <form-field v-model="field.value" :field="field" :field-index="fieldIndex" />
      </template>
      <div class="tw-col-span-12">
        <nc-button
          :label="form.button.text"
          data-wait="Please wait..."
          class="tw-w-full"
          @onClick="onSendForm()"
        ></nc-button>
      </div>
    </div>
    <div v-show="isLoading" :style="`height: ${formHeight}px`">
      <loader />
    </div>
    <div v-if="isSuccess" class="form-message-success">
      <div v-html="successMessageText"></div>
    </div>
  </div>
  <div :id="formVirtualKeyAndId" v-else-if="version === 'v2' && formVirtualKeyAndId">
    <div v-if="errors && Object.keys(errors).length > 0 && !isSuccess && !isLoading" class="tw-mb-4">
      <ul>
        <li v-for="(item, index) in Object.keys(errors)" :key="`${index}-error-item`">
          <span v-if="errors[item] && errors[item][0]">
            {{ errors[item][0] }}
          </span>
        </li>
      </ul>
    </div>

    <div
      v-show="!isLoading && !isSuccess"
      class="form tw-grid tw-grid-cols-12 tw-gap-x-3 tw-gap-y-3"
      :data-lifesycle-form-id="formUniqueId"
    >
      <h3 v-if="isFormNameVisible" class="tw-col-span-12">{{ form.name }}</h3>
      <template v-for="(field, fieldIndex) in form.fields" :key="`form-field-${fieldIndex}`">
        <form-field v-model="field.value" :field="field" :field-index="fieldIndex" />
      </template>

      <div class="tw-col-span-12">
        <button class="n-primary" @click="onSendForm()">
          {{ form.button.text }}
        </button>
      </div>
    </div>
    <div v-show="isLoading" :style="`height: ${formHeight}px`">
      <loader />
    </div>
    <div v-if="isSuccess" class="form-message-success">
      <div v-html="successMessageText"></div>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import Loader from '~/components/common/Loader.vue';
import NcCheckbox from '~/components/common/inputs/NcCheckbox.vue';
import type { FormFieldItem } from '~/components/theme2/base/FormField.vue';
import FormField from '~/components/theme2/base/FormField.vue';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { ComponentMixin } from '~/mixins/component.mixin';
import { mapActions } from 'pinia';
import { useFormsStore } from '~/units/forms/store';
import { FormFieldTypes, type FormPayloadData } from '~/units/forms/types';
import { produceKey } from '~/server/core/helpers';
import { formatDate } from '~/units/core/providers/date.provider';

export interface Form {
  action: string;
  method: string;
  'data-lifesycle-form-id': string;
  fields: Array<FormFieldItem>;
  button: {
    type: string;
    text: string;
  };
  form_id: number;
}

export default defineNuxtComponent({
  name: 'LibraryForm',
  components: { NcButton, FormField, NcCheckbox, Loader },

  mixins: [ComponentMixin],

  props: {
    form: {
      required: true,
      type: Object as PropType<Form>,
    },

    version: {
      required: false,
      type: String,
      default: 'v1',
    },

    isFormNameVisible: {
      required: false,
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isSuccess: false,
      isLoading: false,
      isError: false,

      successMessageText: '',
      errors: [],

      formHeight: 0,
      formVirtualKey: '',
    };
  },

  created() {
    this.form.fields.forEach((field) => {
      if (field.field_type_id === FormFieldTypes.CHECKBOX) {
        field.value = [];
      } else if (field.field_type_id === FormFieldTypes.ADDRESS) {
        field.value = null;
      } else if (field.field_type_id === FormFieldTypes.DATE) {
        field.value = null;
      }
    });
  },

  async mounted() {
    this.formVirtualKey = produceKey();

    await this.$nextTick(() => {
      const element = document.getElementById(this.formVirtualKeyAndId);
      if (element) {
        this.formHeight = element.offsetHeight;
      }
    });
  },

  computed: {
    formVirtualKeyAndId() {
      return `${this.formVirtualKey}-form`;
    },

    formUniqueId() {
      return this.form['data-lifesycle-form-id'];
    },

    isNeuronManagedForm() {
      return this.vars.isNeuronManagedFormBool;
    },
  },

  methods: {
    ...mapActions(useFormsStore, ['sendFormToAkya']),

    async onSendForm() {
      this.isLoading = true;
      try {
        const formData: FormPayloadData = {};

        this.form.fields.forEach((item) => {
          if (item.label === 'Content Title') {
            formData[item.unique_id] = window.document.title;
          } else if (item.label === 'Url') {
            formData[item.unique_id] = window.location.href;
          } else if (item.label.toString().trim() === 'Neuron Form Source' && this.isNeuronManagedForm) {
            const internalFormTitle = this.vars.formInternalTitleForCustomerText as string;
            if (internalFormTitle) {
              formData[item.unique_id] = internalFormTitle;
            } else {
              formData[item.unique_id] = window.document.title;
            }
          } else if (item.field_type_id === FormFieldTypes.DATE && item.value) {
            formData[item.unique_id] = formatDate(item.value, 'YYYY-MM-DD');
          } else {
            formData[item.unique_id] = item.value;
          }
        });

        Object.keys(formData).forEach((key) => {
          const value = (() => {
            if (formData[key] && typeof formData[key] === 'string') {
              return formData[key].trim();
            }
            return formData[key];
          })();

          if (!value || value.length === 0) {
            delete formData[key];
          }
        });

        if (Object.keys(formData).length === 0) {
          this.warningMessage('Please fill required fields');
        } else {
          const result = await this.sendFormToAkya(this.formUniqueId, {
            current_url: window.location.href,
            form_data: formData,
            referrer: window.location.origin,
            type: 'contact',
            unique_id: this.formUniqueId,
            form_id: this.form.form_id,
          });

          const redirectUrl = (() => {
            if (this.isNeuronManagedForm) {
              if (this.vars.formRedirectUrlText) {
                return this.vars.formRedirectUrlText;
              }
            } else {
              // lifesycle redirect url
              return result.redirect_url;
            }
          })();

          this.successMessageText = (() => {
            let message = '';
            if (result.success_message) {
              message += result.success_message;
            }

            if (redirectUrl) {
              message += ` If your browser doesn't redirect you, you can click <a href="${redirectUrl}" style="text-decoration: underline; color: blue;" target="_blank">this link</a>.`;
            }

            return message;
          })();

          if (redirectUrl) {
            window.open(redirectUrl, '_blank');
          }

          this.isLoading = false;
          this.isSuccess = true;
        }
      } catch (e) {
        this.errors = e.data.data.result;
      }
      this.isLoading = false;
    },
  },
});
</script>

<style scoped>
.form-message-error {
  background-color: var(--form-errors-background-color);
  color: var(--form-errors-text-color);
  border-radius: 0.4rem;
  padding: 1.125rem;
}
</style>
