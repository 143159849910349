<template>
  <client-only>
    <div class="tw-flex tw-flex-col">
      <lazy-library-text :text="`${label}${isRequired ? '*' : ''}`" tag="span" type="input-label"></lazy-library-text>
      <date-picker
        v-model="childModelValue"
        :mode="mode"
        :masks="dateMasks"
        :name="name"
        :hide-time-header="false"
        is24hr
        :is-required="isRequired"
        :select-attribute="selectAttribute"
      >
      </date-picker>
    </div>
  </client-only>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css';

export default defineNuxtComponent({
  name: 'LibraryDatePicker',
  components: { DatePicker },

  props: {
    modelValue: {
      required: true,
    },

    dateMasks: {
      required: false,
      type: Object,
      default: () => {
        return {
          input: 'DD/MM/YYYY',
        };
      },
    },

    name: {
      required: false,
      type: String,
      default: '',
    },

    label: {
      required: false,
      type: String,
      default: '',
    },

    isRequired: {
      required: false,
      type: Boolean,
      default: false,
    },

    mode: {
      required: false,
      type: String as PropType<'date'>,
      default: 'date',
    },
  },

  computed: {
    childModelValue: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit('update:modelValue', value);
      },
    },

    selectAttribute() {
      return {
        highlight: {
          style: {
            // Circle styles
            background: 'blue',
          },
          contentStyle: {
            // Text styles
            color: 'white',
          },
        },
      };
    },
  },
});
</script>
