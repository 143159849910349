type AddressInputPayload = {
  address_1: string;
  address_2: string;
  address_3: string;
  address_4: string;
  address_5: string;
  postcode: string;
  display_name: string;
  country_id: number;
};

export interface FormPayloadData {
  [key: string]: string | string[] | Date | boolean | AddressInputPayload;
}

export type FormPayload = {
  current_url: string;
  form_data: FormPayloadData;
  referrer: string;
  type: 'contact';
  unique_id: string;
};

export enum FormFieldTypes {
  TEXT = 1,
  NUMBER = 2,
  SELECT_BOX = 3,
  CHECKBOX = 4,
  RADIO = 5,
  DATE = 6,
  EMAIL = 7,
  PHONE = 8,
  TEXTAREA = 9,
  HIDDEN = 10,
  YES_OR_NO = 11,
  SLIDER = 12,
  RATING = 13,
  LEGACY = 14,
  FILE = 15,
  TIME = 16,
  DATETIME = 17,
  COLOR = 18,
  PRICE = 21,
  ADDRESS = 22,
  FIRST_NAME = 23,
  SURNAME = 24,
  GENDER = 25,
  SALUTATION = 26,
  JOB_TITLE = 27,
  LANDLINE_PHONE = 28,
  TRANSACTION_TYPE = 29,
  PROPERTY_TYPE = 30,
}
